<template>
    <div class="uploader-component" :class="{ drop: isDropping, contains: file !== null }" @dragover.prevent="onDragOver" @drop.prevent="onDrop" @dragleave.prevent="onDragLeave">
        <div class="uploader-drop-zone" :style="sizeStyle">
            <div class="preview-wrapper">
                <div class="uploader-preview image" v-if="previews.image">
                    <img :src="getImagePreview" alt="" class="preview-image" />
                </div>
                <div class="uploader-preview document" v-if="previews.document">
                    <svg xmlns="http://www.w3.org/2000/svg" class="ionicon" viewBox="0 0 512 512">
                        <path
                            d="M416 221.25V416a48 48 0 01-48 48H144a48 48 0 01-48-48V96a48 48 0 0148-48h98.75a32 32 0 0122.62 9.37l141.26 141.26a32 32 0 019.37 22.62z"
                            fill="none"
                            stroke="currentColor"
                            stroke-linejoin="round"
                            stroke-width="32" />
                        <path d="M256 56v120a32 32 0 0032 32h120M176 288h160M176 368h160" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="32" />
                    </svg>
                    <div class="document-name">{{ getDocumentName }}</div>
                </div>
            </div>
            <label v-if="!disabled" :for="randomID">Click here or drop a file to upload</label>
            <label v-else :for="randomID">No files have been uploaded</label>
            <div class="progress-bar" v-show="uploadProgress > 0.0">
                <div class="progress-bar-inner" :style="`width: ${uploadProgress * 100}%;`"></div>
            </div>
            <svg class="button remove" viewBox="0 0 24 24" @click.prevent="deleteUploadedFile" v-if="file !== null && !disabled">
                <path
                    fill="currentColor"
                    d="M12,20C7.59,20 4,16.41 4,12C4,7.59 7.59,4 12,4C16.41,4 20,7.59 20,12C20,16.41 16.41,20 12,20M12,2C6.47,2 2,6.47 2,12C2,17.53 6.47,22 12,22C17.53,22 22,17.53 22,12C22,6.47 17.53,2 12,2M14.59,8L12,10.59L9.41,8L8,9.41L10.59,12L8,14.59L9.41,16L12,13.41L14.59,16L16,14.59L13.41,12L16,9.41L14.59,8Z" />
            </svg>
        </div>
        <input class="input" :id="randomID" type="file" name="file" ref="input" @change="onInputChanged" :accept="getInputAcceptsString" />
        <div class="validation-messages" v-if="validationError" :style="`max-width: ${width};`">
            <div class="validation-message" v-html="validationError"></div>
        </div>
    </div>
</template>

<script>
import { resolveMime } from 'friendly-mimes';
import { mapGetters } from 'vuex';
import axios from 'axios';
export default {
    name: 'Uploader',
    data() {
        return {
            previews: {
                image: false,
                document: false
            },
            isDropping: false,
            file: null,
            validationError: '',
            uploadProgress: 0,
            uploadedObjectId: '',
            isPreview: false
        };
    },
    props: {
        width: {
            type: String,
            default: '100%',
            required: false
        },
        accepts: {
            type: Array,
            required: true
        },
        maxSize: {
            type: Number,
            required: false,
            default: 10485760
        },
        context: {
            required: true,
            type: String,
            default: 'general'
        },
        value: {
            required: false
        },
        disabled: {
            required: false,
            type: Boolean,
            default: false
        }
    },
    computed: {
        ...mapGetters('apiAuth', { authUser: 'user' }),
        ...mapGetters('apiAuth', { authToken: 'accessToken' }),
        sizeStyle: function () {
            return `width: ${this.width}; height: 0; padding-bottom: ${this.width};`;
        },
        randomID: function () {
            return 'xxxx_xxxx_xxxx_xxxx'.replace(/[x]/g, (c) => {
                const r = Math.floor(Math.random() * 16);
                return r.toString(16);
            });
        },
        getImagePreview() {
            if (!this.file || !this.previews.image) return;
            return URL.createObjectURL(this.file);
        },
        getDocumentName() {
            if (!this.file || !this.previews.document) return;
            return this.file.name;
        },
        getInputAcceptsString() {
            return this.accepts.join(', ');
        }
    },
    methods: {
        onDragOver() {
            if (this.disabled) return;
            this.isDropping = true;
        },
        onDrop(event) {
            if (this.disabled) return;
            this.isDropping = false;
            this.file = event.dataTransfer.items[0].getAsFile();
            this.processFile();
        },
        onDragLeave() {
            if (this.disabled) return;
            this.isDropping = false;
        },
        onInputChanged(event) {
            if (this.disabled) return;
            this.isDropping = false;
            this.file = event.target.files[0];
            this.processFile();
        },
        processFile() {
            this.validationError = '';
            this.validateType();
            this.validateSize();
            if (!this.file) {
                return;
            }

            this.isPreview = false;

            this.uploadFile();
        },
        uploadFile() {
            if (!this.file || this.disabled) return;

            if (!this.context) {
                console.error('Missing required properties on Uploader component. Context required');
                return;
            }

            this.uploadProgress = 0.0;
            var formData = new FormData();
            formData.append('context', this.context);
            formData.append('userId', this.authUser ? this.authUser.id : 'null');
            formData.append('file', this.file);

            axios
                .request({
                    method: 'post',
                    url: this.$feathersConnectionString + '/uploads',
                    data: formData,
                    onUploadProgress: (p) => {
                        this.uploadProgress = p.loaded / p.total;
                    }
                })
                .catch((err) => {
                    this.validationError = 'Failed to upload file';
                    console.warn(err);
                    this.clearFile();
                })
                .then((response) => {
                    this.uploadProgress = 1.0;
                    this.$emit('input', response.data);
                    // NOTE: Keep track of ID returned from Feathers for deletion
                    this.uploadedObjectId = response.data.id;
                });
        },
        clearFile() {
            this.file = null;
            this.previews.image = this.previews.document = false;
            this.uploadProgress = 0.0;
            this.uploadedObjectId = '';
            this.$emit('input', null);
        },
        deleteUploadedFile() {
            if (this.disabled) return;
            axios
                .request({
                    method: 'delete',
                    url: this.$feathersConnectionString + `/uploads/${this.uploadedObjectId}`,
                    headers: {
                        Authorization: `Bearer ${this.authToken}`
                    }
                })
                .catch((err) => {
                    this.validationError = 'Failed to delete file';
                    console.warn(err);
                })
                .then((response) => {
                    this.$emit('input', null);
                    this.$emit('onRemove');
                    this.clearFile();
                });
        },
        validateType() {
            if (!this.file) return;
            if (!this.accepts.includes(this.file.type)) {
                var types = this.accepts.map((v) => {
                    return resolveMime(v).fileType;
                });
                this.validationError = `File is not one of the <span style="font-style: italic; text-decoration: underline; cursor: help;" title="${types.join(', ')}">accepted types</span>`;
                if (!this.isPreview) this.clearFile();
            }
        },
        validateSize() {
            if (!this.file) return;
            if (this.file.size > this.maxSize) {
                this.validationError = `File exceeds maximum size of <span style="font-style: italic;">${this.bytesToSize(this.maxSize)}</span>`;
                this.file = null;
                if (!this.isPreview) this.clearFile();
            }
        },
        bytesToSize(bytes) {
            const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB'];
            if (bytes === 0) return 'n/a';
            const i = parseInt(Math.floor(Math.log(bytes) / Math.log(1024)), 10);
            if (i === 0) return `${bytes} ${sizes[i]})`;
            return `${(bytes / 1024 ** i).toFixed(1)} ${sizes[i]}`;
        }
    },
    watch: {
        file: {
            deep: true,
            immediate: true,
            handler() {
                if (!this.file) return;
                if (['image/gif', 'image/jpeg', 'image/png', 'image/bmp', 'image/webp'].includes(this.file.type)) {
                    this.previews.image = true;
                    this.previews.document = false;
                } else {
                    this.previews.document = true;
                    this.previews.image = false;
                }
            }
        }
    },
    async mounted() {
        if (this.value?.url) {
            this.isPreview = true;
            let previewFileName = this.value.url.match(/([^\/\\]*)\/*$/)[1] || `preview_${new Date().getTime()}`;
            let file = await fetch(`${this.$feathersConnectionString}/${this.value.url}`)
                .then((r) => r.blob())
                .then((blobFile) => new File([blobFile], previewFileName, { type: this.value.mime }));
            this.file = file;
            this.uploadedObjectId = this.value.id;
        }
    }
};
</script>

<style lang="scss" scoped>
.uploader-component {
    position: relative;
    .uploader-drop-zone {
        position: relative;
        outline: 2px dashed;
        outline-color: transparentize(rgb(111, 112, 255), 0.8);
        outline-offset: -2px;
        transition: outline 150ms ease-out, outline-offset 150ms ease-out, outline-color 150ms ease-out;
        .preview-wrapper {
            padding: 10px;
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            box-sizing: border-box;
            overflow: hidden;

            .uploader-preview {
                z-index: 2;
                position: absolute;
                top: 10px;
                left: 10px;
                width: calc(100% - 20px);
                height: calc(100% - 20px);

                &.image {
                    .preview-image {
                        width: 100%;
                        height: 100%;
                        object-fit: cover;
                    }
                }
                &.document {
                    // display: flex;
                    // align-items: center;
                    // justify-content: center;
                    display: flex;
                    flex-flow: column nowrap;
                    justify-content: center;
                    align-items: center;
                    text-align: center;
                    svg {
                        width: 48px;
                        height: 48px;
                        margin-bottom: 10px;
                    }
                }
            }
        }
        label {
            position: absolute;
            top: 50%;
            left: 50%;
            text-align: center;
            transform: translate(-50%, -50%);
            width: calc(100% - 2rem);
            cursor: pointer;
        }
    }
    .input {
        width: 0;
        height: 0;
        overflow: hidden;
        opacity: 0;
        position: absolute;
    }

    &.drop {
        .uploader-drop-zone {
            outline-offset: -0.5rem;
            outline-color: transparentize(rgb(111, 112, 255), 0.25);
        }
    }

    &.contains {
        label {
            display: none;
        }
        .uploader-drop-zone {
            outline-offset: -2px;
            outline-color: transparentize(rgb(111, 112, 255), 0.25);
        }
    }

    .validation-messages {
        margin-top: 10px;
        .validation-message {
            text-align: center;
        }
    }

    .progress-bar {
        position: absolute;
        bottom: 10px;
        left: 10px;
        height: 5px;
        background-color: rgba(0, 0, 0, 0.1);
        width: calc(100% - 20px);
        // border-radius: 3px;
        overflow: hidden;
        z-index: 3;
        .progress-bar-inner {
            position: absolute;
            left: 0;
            top: 0;
            height: 100%;
            background-color: transparentize(rgb(111, 112, 255), 0.25);
            // border-radius: 3px;
        }
    }

    .button {
        position: absolute;
        top: 10px;
        right: 10px;
        width: 30px;
        height: 30px;
        z-index: 4;
        opacity: 1;
        -webkit-filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
        filter: drop-shadow(3px 3px 3px rgba(0, 0, 0, 0.5));
        cursor: pointer;

        path {
            fill: white;
        }
    }
}
</style>